import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

import { Link as MuiLink } from '@material-ui/core';

const Link = React.forwardRef((props, ref) => {
  const isInternalLink = ['/', '../'].some(internalLinkStart => props?.to?.startsWith(internalLinkStart));
  const content = isInternalLink
    ? <MuiLink component={GatsbyLink} innerRef={ref} {...props} />
    : <MuiLink href={props?.to} target="_blank" rel="noreferrer" {...props} />;

  return (content);
});

Link.displayName = 'LocalizedLink';

export default Link;
