import React from 'react';
import { withPrefix } from 'gatsby';
import clsx from 'clsx';

import { Paper, Container, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMedia from '../hooks/useMedia';
import { fromMediumScreenMediaQuery } from '../helpers/commonHelpers';

import Link from './Link';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  logo: {
    maxWidth: theme.spacing(10),
  },
}));

const Header = ({ className, ...props }) => {
  const classes = useStyles();

  const isTabletOrMobile = useMedia(
    [fromMediumScreenMediaQuery],
    [true],
    false,
  );

  return (
    <Paper
      component="header"
      variant="outlined"
      className={clsx(className, classes.root)}
      {...props}
    >
      <Container>
        <Grid container spacing={1}>
          <Grid item>
            <Link to="/">
              <img src={withPrefix('/logo.png')} alt="" className={classes.logo} />
            </Link>
          </Grid>

          { Boolean(!isTabletOrMobile) && (
            <Grid item xs>
              <Typography variant="h1" component={Link} to="/">
                La Vie De La Rivière
              </Typography>

              <Typography variant="subtitle1">
                La vie du bassin-versant du Lot (rivière Lot et affluents)
              </Typography>
            </Grid>
          )}

          <Grid container item xs direction="column" justifyContent="center" alignItems="flex-end">
            <Grid item>
              <Link to="/">Carte générale</Link>
            </Grid>
            <Grid item>
              <Link to="/stations">Liste des stations</Link>
            </Grid>
            <Grid item>
              <Link to="/seuils">Débits moyens journaliers</Link>
            </Grid>
          </Grid>
        </Grid>

      </Container>
    </Paper>
  );
};

export default Header;
