import React from 'react';
import clsx from 'clsx';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Footer from './Footer';
import Header from './Header';
import { fromSmallScreenMediaQuery } from '../helpers/commonHelpers';

import favicon from '../../static/logo.png';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    minHeight: '100vh',
  },
});

const Layout = ({
  className,
  noHeader,
  noFooter,
  header,
  footer,
  title = '',
  ...rest
}) => {
  // Start of REDUX STORE INITIALIZATION.
  /**
   * The following code section initializes the JS application Redux store with
   * all the default values that are dynamic.
   * Everything in the initialState.js file is resolved at Gatsby build time
   * and as a result is static. Hence, everything that needs dynamic values or
   * the Browser context to be set is run once here when the application
   * launches.
   *
   * Why here?
   * Layout is used on all pages, so we're sure this will be executed once the
   * application is run, whatever the entry page.
   */
  const localIsStoreInitialized = useSelector(({ isStoreInitialized }) => isStoreInitialized);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!localIsStoreInitialized) {
      // Initialize main date with today's date.
      dispatch({ type: 'DATE_SET', date: dayjs(new Date()).format('YYYY-MM-DD') });
      // Initialize main side bar collapse state based on current device's
      // screen size.
      const isTabletOrMobile = window.matchMedia(fromSmallScreenMediaQuery).matches;
      dispatch({ type: 'HOME_DRAWER_TOGGLE', force: !isTabletOrMobile });

      // Remember initialization was done so it doesn't get run again.
      dispatch({ type: 'SET_STORE_INITIALIZED', value: true });
    }
  });
  // End of REDUX STORE INITIALIZATION.

  const classes = useStyles();

  const showHeader = noHeader !== true && header !== false;
  const showFooter = noFooter !== true && footer !== false;

  return (
    <div className={classes.root}>
      <Helmet
        htmlAttributes={{ lang: 'fr' }}
        title={title}
        titleTemplate="%s | La vie de la rivière"
        link={[
          { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` },
        ]}
      />
      {showHeader && <Header />}

      <Container
        component="main"
        className={clsx(classes.main, className)}
        {...rest}
      />

      {showFooter && <Footer />}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
