import React from 'react';
import clsx from 'clsx';

import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  defaultStyle: {
    marginTop: 'auto',
  },
});

const Footer = ({ className, ...props }) => {
  const classes = useStyles();
  return (
    <Container className={clsx(className, classes.defaultStyle)} {...props}>
      footer content
    </Container>
  );
};

export default Footer;
