/* eslint-disable no-plusplus */
import dayjs from 'dayjs';
import { stringify } from 'qs';
import { zeropad, hmsString_, DateAccessorsUTC, DateAccessorsLocal } from '@qogni/dygraphs/src-es5/dygraph-utils';
import { Granularity } from '@qogni/dygraphs/src-es5/dygraph-tickers';

export const dataModes = [
  { id: 'Qi', label: 'Qi', description: 'Débit instantané', timeStep: [2, 'hour'] },
  { id: 'QmJ', label: 'QmJ', description: 'Débit moyen journalier', timeStep: [1, 'day'] },
  { id: 'Qm3J', label: 'Qm3J', description: 'Débit moyen sur 3 jours consécutifs', timeStep: [1, 'day'] },
  { id: 'Qm10J', label: 'Qm10J', description: 'Débit moyen sur 10 jours consécutifs', timeStep: [1, 'day'] },
  { id: 'QmH', label: 'QmH', description: 'Débit moyen horaire', timeStep: [2, 'hour'] },
  { id: 'H', label: 'H', description: 'Hauteur d\'eau instantanée', timeStep: [2, 'hour'] },
];

export const anteriorities = [
  { id: '1j', label: '1j', description: 'Une journée', period: [1, 'day'] },
  { id: '7j', label: '7j', description: 'Période de 7 jours', period: [7, 'day'] },
  { id: '15j', label: '15j', description: 'Période de 15 jours', period: [15, 'day'] },
  { id: '1m', label: '1m', description: 'Période d\'1 mois', period: [1, 'month'] },
  { id: '2m', label: '2m', description: 'Période de 2 mois', period: [2, 'month'] },
  { id: '3m', label: '3m', description: 'Période de 3 mois', period: [3, 'month'] },
];

const SHORT_MONTH_NAMES_FR = ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'];

// List of anteriority configurations that should be included in the front end.
export const frontAnteriorities = ['7j', '15j', '1m', '2m', '3m'];

export const dataStartDate = new Date(2011, 3, 16);

/**
 * Contains anteriority configurations that should be displayed on the front end
 * for users to select.
 */
export const frontAnteriorityConfigs = anteriorities.filter(
  ({ id }) => frontAnteriorities.includes(id),
);

export const apiTimeFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';
export const storeDateFormat = 'YYYY-MM-DD';

const createTimeVoidBetweenDates = ({
  dateStart, dateEnd,
  timeStepNumber, timeStepUnit,
  marginRatio = 1.1,
  shouldIncludeFirstDate = false,
  isBetweenExistingValue = true,
  ids = null,
}) => {
  const timeStepDifference = dayjs(dateEnd).diff(dayjs(dateStart), timeStepUnit);
  const defaultEmptyValues = {
    v: Array.isArray(ids)
      ? Object.fromEntries(ids.map(id => [id, NaN]))
      : '',
  };

  if (timeStepDifference > timeStepNumber * marginRatio) {
    const subtractTimeStep = isBetweenExistingValue ? 1 : 0;
    const stepstoAdd = timeStepDifference - subtractTimeStep;
    const startIndex = shouldIncludeFirstDate ? 0 : 1;

    return Array.from({ length: stepstoAdd - startIndex }, (_, index) => ({
      d: dateStart.add(startIndex + index, timeStepUnit).format(apiTimeFormat),
      ...defaultEmptyValues,
    }));
  }

  return [];
};

export const dateAxisLabelFormatter = (date, granularity, opts) => {
  const utc = opts('labelsUTC');
  const accessors = utc ? DateAccessorsUTC : DateAccessorsLocal;

  const year = accessors.getFullYear(date);
  const month = accessors.getMonth(date);
  const day = accessors.getDate(date);
  const hours = accessors.getHours(date);
  const mins = accessors.getMinutes(date);
  const secs = accessors.getSeconds(date);
  const millis = accessors.getMilliseconds(date);

  if (granularity >= Granularity.DECADAL) {
    return `${year}`;
  } if (granularity >= Granularity.MONTHLY) {
    return `${SHORT_MONTH_NAMES_FR[month]}&#160;${year}`;
  }
  const frac = hours * 3600 + mins * 60 + secs + 1e-3 * millis;
  if (frac === 0 || granularity >= Granularity.DAILY) {
    // e.g. '21 Jan' (%d%b)
    return `${zeropad(day)}&#160;${SHORT_MONTH_NAMES_FR[month]}`;
  } if (granularity < Granularity.SECONDLY) {
    // e.g. 40.310 (meaning 40 seconds and 310 milliseconds)
    const str = `${millis}`;
    return `${zeropad(secs)}.${(`000${str}`).substring(str.length)}`;
  } if (granularity > Granularity.MINUTELY) {
    return hmsString_(hours, mins, secs, 0);
  }
  return hmsString_(hours, mins, secs, millis);
};

const decimalCount = x => {
  // Convert to String
  const xAsString = String(x);
  // String Contains Decimal
  if (xAsString.includes('.')) {
    return xAsString.split('.')[1].length;
  }

  return 0;
};

export const numberValueFormatter = maxDigit => x => {
  const xAsFloat = parseFloat(x);
  const numDec = decimalCount(xAsFloat);

  return !Number.isNaN(xAsFloat)
    ? xAsFloat.toLocaleString('fr', { minimumFractionDigits: Math.min(maxDigit, numDec), maximumFractionDigits: Math.min(maxDigit, numDec) })
    : '-';
};

export const dateValueFormatter = isDailyDate => x => {
  const d = dayjs(x);
  const format = isDailyDate ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:MM';

  return d.format(format);
};

export const checkTimeVoid = (
  series = [],
  timeStep,
  fixedDateStart = null,
  fixedDateEnd = null,
  ids = null,
) => {
  let blankValues = [];
  const [timeStepNumber, timeStepUnit] = timeStep;
  const fixedStart = dayjs(fixedDateStart, apiTimeFormat);
  const fixedEnd = dayjs(fixedDateEnd, apiTimeFormat);
  // check for empty values before the first one if any
  const shouldIncludeFirstDate = true;
  const isBetweenExistingValue = false;
  const marginRatio = 1;

  const commonArgs = {
    timeStepNumber,
    timeStepUnit,
    marginRatio,
    shouldIncludeFirstDate,
    isBetweenExistingValue,
    ids,
  };

  if (fixedDateStart && series.length > 1) {
    const { d: firstDateValue } = series[0];
    const firstDate = dayjs(firstDateValue, apiTimeFormat);
    blankValues = [
      ...blankValues,
      ...createTimeVoidBetweenDates({
        ...commonArgs,
        dateStart: fixedStart,
        dateEnd: firstDate,
      })];
  }

  // check for empty values after the last one if any
  if (fixedDateEnd && series.length > 1) {
    const { d: endDateValue } = series[series.length - 1];
    const endDate = dayjs(endDateValue, apiTimeFormat);
    blankValues = [
      ...blankValues,
      ...createTimeVoidBetweenDates({
        ...commonArgs,
        dateStart: endDate,
        dateEnd: fixedEnd,
        shouldIncludeFirstDate: !shouldIncludeFirstDate,
      })];
  }

  // if we have fixed start & end date and if serie is null
  // fill all dates in between with blank values
  if (fixedDateStart && fixedDateEnd && series.length === 0) {
    blankValues = [
      ...blankValues,
      ...createTimeVoidBetweenDates({
        ...commonArgs,
        dateStart: fixedStart,
        // if the serie array is empty, we need to also add the end date as an entry
        // which is not included otherwise
        dateEnd: fixedEnd.add(1, timeStepUnit),
      })];
  } else {
    // check for empty values inside the initial serie
    series.forEach((item, seriesIndex) => {
      const nextItem = series[seriesIndex + 1];

      if (!nextItem) {
        return;
      }

      blankValues = [
        ...blankValues,
        ...createTimeVoidBetweenDates({
          ...commonArgs,
          dateStart: dayjs(item.d, apiTimeFormat),
          dateEnd: dayjs(nextItem.d, apiTimeFormat),
          shouldIncludeFirstDate: false,
          isBetweenExistingValue: false,
        }),
      ];
    });
  }

  return blankValues;
};

/**
 * Returns an anteriority configuration based on its id.
 * @param {string} anteriorityId Id of the anteriority to return.
 * @return {Object} Matching anteriority. Defaults to the first anteriority if
 *                  none was found; that is 1 day.
 */
export const getAnteriorityConfiguration = anteriorityId => {
  const match = anteriorities.find(anteriority => anteriority.id === anteriorityId);

  return match !== undefined
    ? match
    : anteriorities[0];
};

export const formatSingleSeriesForGraph = (series = [], mode, start, end) => {
  // check for gaps
  const timeStep = dataModes.find(({ id }) => id === mode)?.timeStep;
  const blankValues = checkTimeVoid(series, timeStep, start, end) || [];
  const formattedSeries = [...series, ...blankValues]
    .sort(({ d: a }, { d: b }) => dayjs(a) - dayjs(b))
    .map(observation => `${observation.d},${observation.v}`);
  return [`Date,${mode}\n`, ...formattedSeries].join('\n');
};

export const formatMultiSeriesForGraph = (series = [], ids, mode, start, end) => {
  if (!Array.isArray(series)) {
    return [];
  }

  const timeStep = dataModes.find(({ id }) => id === mode)?.timeStep;
  const blankValues = checkTimeVoid(series, timeStep, start, end, ids) || [];

  const observations = [...series, ...blankValues];

  return observations.map(({ d, v }) => [
    new Date(d),
    ...ids.map(id => v[id]),
  ]).sort(([a], [b]) => a - b);
};

export const availableDataModes = dataTypes => dataModes.map(dataMode => ({
  ...dataMode,
  disable: !dataTypes.includes(dataMode.id),
}));

export const sortBy = (propertyName, reverse) => (a, b) => {
  const inverter = reverse ? -1 : 1;

  const orderedProperty = [
    { name: 'subManagementUnitLabel', orderField: 'subManagementUnitOrder' },
    { name: 'stationLabel', orderField: 'stationOrder' },
  ];

  const isOrderedProperty = orderedProperty.find(({ name }) => name === propertyName);
  if (isOrderedProperty) {
    const { [isOrderedProperty.orderField]: valueA } = a;
    const { [isOrderedProperty.orderField]: valueB } = b;
    return (valueA - valueB) * inverter;
  }

  const { [propertyName]: valueA } = a;
  const { [propertyName]: valueB } = b;
  return (valueA || 0).toString().localeCompare((valueB || 0).toString()) * inverter;
};

export const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    // eslint-disable-next-line no-param-reassign
    objectsByKeyValue[value] = [...objectsByKeyValue[value] || [], obj];
    return objectsByKeyValue;
  }, {});

export const transposeStatuses = (arr, key) =>
  arr.reduce(
    (acc, node) => (node.status ? { ...acc, [node[key]]: node } : acc),
    {},
  );

export const transposeLiveStatuses = obj => {
  try {
    return Object.entries(obj).reduce(
      (acc, [id, status]) => ({ ...acc, [id]: { status, featureId: id } }),
      {},
    );
  } catch (e) {
    return null;
  }
};

export const fromSmallScreenMediaQuery = '(max-width: 1000px)';

export const fromMediumScreenMediaQuery = '(max-width: 1224px)';
export const highlightTypes = {
  city: {
    id: 'id',
    location: 'cities.geojson',
    style: { weight: 2.5, fillOpacity: 0.5 },
  },
  sub_management_unit: {
    id: 'id',
    location: 'zones.geojson',
    style: { weight: 5, fillOpacity: 0.5 },
  },
  hydro_station: {
    id: 'code',
    location: 'stations.geojson',
    style: { weight: 5, radius: 10, fillOpacity: 0.7 },
  },
};

/**
 * Re order thresholds object to match display order
 * DOE > DA > DAR > DCR
 */
export const getThresholdsOrdered = ({ doe = null, da = null, dar = null, dcr = null }) => {
  return {
    ...(doe && {doe}),
    ...(da && {da}),
    ...(dar && {dar}),
    ...(dcr && {dcr}),
  }
};

export const chartColor = ['#274458'];
export const vcnxColor = ['#4daf4a'];
export const vcnxAltColor = ['#94cf92'];
export const chartThresholdsColor = ['#88ce33', '#f5dc0b', '#f5860b', '#e53e1d'];
export const colors = ['#e41a1c', '#377eb8', '#4daf4a', '#984ea3', '#ff7f00', '#ffff33', '#a65628', '#f781bf', '#999999'];
export const lighterColors = ['#fbb4ae', '#b3cde3', '#ccebc5', '#decbe4', '#fed9a6', '#ffffcc', '#e5d8bd', '#fddaec', '#f2f2f2'];
export const dailyQuantilesColors = ['#808080', '#ff8669', '#f4b183', '#c5e0b4', '#bdd7ee', '#b4c7e7', '#8faadc'];
export const dailyQuantilesLabels = ['Minimum', 'Décennale sèche', 'Quinquennale sèche', 'Médiane', 'Quinquennale humide', 'Décennale humide', 'Maximum'];
export const thresholdColors = [
  '#88ce33',
  '#f5dc0b',
  '#f5860b',
  '#e53e1d',
  'black',
  'lightgrey',
];

export const drawThresholdsAndVcnx = (
  thresholds = {},
  vcn3 = [],
  vcn10 = [],
) => (canvas, area, g) => {
  if(thresholds) {
    Object.values(thresholds).forEach((t, index) => {
      const y = g.toDomYCoord(t);
      canvas.save();
      canvas.beginPath();
      canvas.strokeStyle = chartThresholdsColor[index];
      canvas.moveTo(area.x, y);
      canvas.lineTo(area.w + area.x, y);
      canvas.stroke();
      canvas.restore();
    });
  }
  (vcn3.concat(vcn10)).forEach((vcnx, idx) => {
    if (vcnx.v !== null) {
      const y = g.toDomYCoord(vcnx.v);
      canvas.save();
      canvas.beginPath();
      canvas.setLineDash([2, 2]);
      canvas.lineWidth = 2;
      canvas.strokeStyle = idx % 2 ? vcnxColor : vcnxAltColor;
      canvas.moveTo(area.x, y);
      canvas.lineTo(area.w + area.x, y);
      canvas.stroke();
      canvas.restore();
    }
  });
};

const getSerieLegend = element => `
  <span>
    <div class="dygraph-legend-line"></div> 
    <span style="font-weight:bold; color:${element.color};">${element.labelHTML}</span>
    <span>: ${element.yHTML || '-'}</span>
  </span>
`;

const dateLegendDisplay = date => (date ? `
  <span>
    <div class="dygraph-legend-line"></div> 
    ${date} 
  </span>
` : '');

const thresholdLegendDisplay = (thresholdLabel, thresholdValue, color) => `
  <span>
    <span style="font-weight:bold; color:${color};">${thresholdLabel.toUpperCase()}</span>
    <span>: ${numberValueFormatter(4)(thresholdValue)}</span>
  </span>
`;

const getThresholdsLegend = thresholds => Object.entries(thresholds).map(
  ([label, value], index) => thresholdLegendDisplay(label, value, chartThresholdsColor[index]),
);

export const legendFormatterDailyQuantiles = ({ xHTML: date, series = [] }) => [
  dateLegendDisplay(date),
  ...series.map(seriesData => getSerieLegend(seriesData)).reverse(),
].join('');

export const legendFormatterSingleSeries = (
  thresholds,
  mode,
  vcn3 = [],
  vcn10 = [],
) => ({ xHTML: date = '', series = [] }) => {
  const thresholdsLabels = !thresholds
    ? ''
    : getThresholdsLegend(thresholds);
  const vcn3Labels = Array.isArray(vcn3) && vcn3.length !== 0 ? vcn3.map((data, index) => thresholdLegendDisplay(`VCN3 (${dayjs(data.d, apiTimeFormat).format('DD/MM/YYYY')})`, data.v.toFixed(3), index % 2 ? vcnxColor : vcnxAltColor)) : [];
  const vcn10Labels = Array.isArray(vcn10) && vcn10.length !== 0 ? vcn10.map((data, index) => thresholdLegendDisplay(`VCN10 (${dayjs(data.d, apiTimeFormat).format('DD/MM/YYYY')})`, data.v.toFixed(3), index % 2 ? vcnxColor : vcnxAltColor)) : [];

  return [
    dateLegendDisplay(date),
    getSerieLegend(series[0]),
    ...thresholdsLabels
  ].concat(vcn3Labels).concat(vcn10Labels).join('');
};

export const legendFormatterMultiSeries = ({ xHTML: date = '', series = [] }) => {
  const seriesLegend = series.map(element => (element.yHTML === undefined
    ? ''
    : getSerieLegend(element)
  ));

  return [
    dateLegendDisplay(date),
    ...seriesLegend,
  ].join('');
};

export const isLive = typeof window !== 'undefined';

export const renameKeys = (object = {}, changes = {}) =>
  Object.fromEntries(
    Object.entries(object)
      .map(([key, value]) => [changes[key] || key, value]),
  );

export const parseCustomStringDateAndFormat = (date, fromFormat = 'YYYY-MM-DD', toFormat = 'YYYYMMDD') => {
  const oDate = dayjs(date, fromFormat);
  if (oDate.isValid()) {
    return oDate.format(toFormat);
  }

  return null;
};

export const toQueryString = (parameters = {}) => stringify(parameters, { encode: false, arrayFormat: 'brackets' });

/**
 * @param {Array} codes
 * @param {String} quantity DayJS
 * @param {Object} endAt DayJS
 * @param {Object} startAt DayJS
 */
export const getStationsUrlFromDates = (codes = [], quantity = 'QmJ', startDate, endDate) => {
  const pathname = `${process.env.GATSBY_LVDLR_API_URL}/api/multiple-entities/observations`;
  const queryString = toQueryString({
    codes,
    quantity,
    endAt: endDate || undefined,
    startAt: startDate || undefined,
  });

  return `${pathname}?${queryString}`;
};

/**
 * @param {Object} date DayJS
 * @return {Object} DayJS
 */
export const getEndOfDay23H59 = (date = dayjs()) => date
  .set('hour', 23)
  .set('minute', 59)
  .set('second', 59)
  .set('millisecond', 999);

export const getStartOfDay00H00 = (date = dayjs()) => date
  .set('hour', 0)
  .set('minute', 0)
  .set('second', 0)
  .set('millisecond', 0);

export const getExceededThreshold = ({ doe, dar, da, dcr }, value) => {
  // if there are no thresholds, this attribute is not relevant
  if (doe === null || !value) {
    return null;
  }
  if (value <= doe && value > dar) {
    return 'doe';
  }
  if (value <= dar && value > da) {
    return 'da';
  }
  if (value <= dar && value > dcr) {
    return 'dar';
  }
  if (value < dcr) {
    return 'dcr';
  }

  return 'none';
};

export const extractGeojsonIDFromURL = url => {
  let id = null;
  if (typeof url === 'string') {
    const lastPartUrl = url.substring(url.lastIndexOf('/') + 1);
    id = lastPartUrl.split('.').slice(0, -1).join('.');
  }

  return id;
};
